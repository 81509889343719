import { Transition } from '@headlessui/react'
import React, { useState } from 'react'
import Link from 'next/link'

import { Button } from './Button'

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const links = [
    { name: 'Docs', url: 'https://docs.rysk.finance/', type: 'link', target: '_blank' },
    { name: 'Blog', url: 'https://medium.rysk.finance/', type: 'link', target: '_blank' },
    { name: 'Start Trading', url: 'https://app.rysk.finance/', type: 'button' },
  ]

  return (
    <>
      <div className="fixed w-full h-24 top-0 flex items-center px-8 md:px-24 justify-between border-b-2 border-black bg-bone z-10">
        <Link className="h-4/5" href="/" passHref>
          <img src={'/logo.png'} alt="logo" className="h-full" />
        </Link>

        <div className="hidden md:block">
          {links.map((link, i) => {
            if (link.type === 'link') {
              return (
                <a className="md:mr-8" href={link.url.toString()} target={link.target} rel="noreferrer" key={i}>
                  {link.name}
                </a>
              )
            } else if (link.type === 'button') {
              return (
                <a key={i} href={link.url.toString()} target={link.target} rel="noreferrer">
                  <Button>{link.name}</Button>
                </a>
              )
            }
          })}
        </div>

        <div className="-mr-2 flex md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="inline-flex items-center justify-center p-2 rounded-md"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            {!isOpen ? (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            ) : (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            )}
          </button>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden" id="mobile-menu">
            <div ref={ref} className="mt-24 px-8 pt-8 space-y-6 sm:px-3">
              {links.map((link, i) => {
                if (link.type === 'link') {
                  return (
                    <a className="block" href={link.url.toString()} target="_blank" rel="noreferrer" key={i}>
                      {link.name}
                    </a>
                  )
                } else if (link.type === 'button') {
                  return (
                    <Button className="block" key={i}>
                      {link.name}
                    </Button>
                  )
                }
              })}
            </div>
          </div>
        )}
      </Transition>
    </>
  )
}
