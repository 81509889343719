import type { PropsWithChildren } from 'react'

import Head from 'next/head'

import { Header } from './shared/Header'

const desc =
  'Decentralized options protocol to trade volatility and directionality with leverage, build 1-click strategies & earn uncorrelated returns.'
const title = 'Rysk: Options trading, simplified'

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={desc} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ryskfinance" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content="https://rysk.finance/rysk_social_cover.png" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.rysk.finance/" />
        <meta property="og:image" content="https://rysk.finance/rysk_social_cover.png" />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:description" content={desc} />

        <link rel="icon" href="/favicon.ico" />
      </Head>

      <header className="bg-bone">
        <Header />
      </header>

      <main className="min-h-[calc(100vh-96px)] bg-bone font-dm-sans overflow-y-hidden">{children}</main>
    </>
  )
}
